'use client';
import React, { useRef, useEffect, useState } from 'react';
import { FocusScope } from '@react-aria/focus';
import PropTypes from 'prop-types';
import { Wrapper, StyledSlidingContainer, Overlay } from './styles.js';

export const SlidingContainer = ({
  children,
  onExit,
  active,
  bgColor = 'background.base',
  alignment = 'right',
  direction = 'slideOut',
  maxWidth = 480,
}) => {
  const overlayRef = useRef(null);

  const [headerHeight, setHeaderHeight] = useState(0);

  const getHeaderHeight = () => {
    try {
      const header = document.querySelector('header');
      return header.getBoundingClientRect().bottom;
    } catch {
      return 0;
    }
  };

  const activeCSS = () => {
    const offsetY = window.pageYOffset;
    document.body.style.top = `${-offsetY}px`;
    document.body.style.position = 'fixed';
    document.body.style.overflow = 'hidden';
    document.body.style.width = '100%';
  };

  const cleanUpCSS = () => {
    document.body.style.removeProperty('top');
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('width');
    document.body.style.overflow = 'visible';
  };

  useEffect(() => {
    if (active) {
      activeCSS();
      setHeaderHeight(getHeaderHeight());
    } else {
      cleanUpCSS();
    }
  }, [active]);

  return (
    <Wrapper
      $direction={direction}
      $headerHeight={headerHeight}
      $forward={active}
    >
      <Overlay
        $active={active}
        ref={overlayRef}
        $direction={direction}
        $headerHeight={headerHeight}
        onClick={() => onExit()}
        key={headerHeight + '_overlay_id'}
      />
      <StyledSlidingContainer
        $active={active}
        $bgColor={bgColor}
        $alignment={alignment}
        $maxWidth={maxWidth}
        $direction={direction}
        $headerHeight={headerHeight}
        key={headerHeight + '_id'}
      >
        {active && (
          <FocusScope contain restoreFocus autoFocus>
            {children}
          </FocusScope>
        )}
      </StyledSlidingContainer>
    </Wrapper>
  );
};

SlidingContainer.displayName = 'SlidingContainer';

SlidingContainer.propTypes = {
  children: PropTypes.node,
  onExit: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  bgColor: PropTypes.oneOf(['background.lightest', 'background.base']),
  alignment: PropTypes.oneOf(['left', 'right']),
  direction: PropTypes.oneOf(['slideOut', 'slideDown']),
  maxWidth: PropTypes.number,
};
