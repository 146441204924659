'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const StyledNavigationBar = styled.nav`
  background-color: ${props => props.theme.colors.get(props.$variant)};
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing(4)};
  position: relative;
`;

export const SkipToContent = styled.a`
  position: absolute;
  left: 0;
  color: ${props => props.theme.colors.text.primary};
  transform: translateX(-110%);
  transition: transform 0.3s;
  padding: 2px 5px;

  &:focus {
    transform: translateY(0%);
  }
`;

export const OllieLogo = styled.div`
  display: flex;

  @media ${props => props.theme.mobileS} {
    flex: 1;
    justify-content: center;
  }

  @media ${props => props.theme.mobileL} {
    flex: ${props => (props.$hasCenterLinks ? 'none' : 1)};
    justify-content: ${props => (props.$hasCenterLinks ? 'left' : 'center')};
  }
`;

export const LogoImgContainer = styled.div`
  max-height: 40px;
  width: 81px;
  height: 40px;
`;

export const LeftAligned = styled.div`
  display: flex;
  justify-content: left;
  @media ${props => props.theme.mobileS} {
    margin-right: ${({ theme }) => theme.spacing(3)};
  }
  @media ${props => props.theme.tablet} {
    margin-right: ${({ theme }) => theme.spacing(0)};
  }
`;

export const CenterAligned = styled.div`
  display: flex;
  justify-content: center;

  @media ${props => props.theme.mobileS} {
    flex: none;
  }

  @media ${props => props.theme.mobileL} {
    flex: ${props => (props.$hasCenterLinks ? 1 : 'none')};
  }
`;

export const RightAligned = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const GetStartedButton = styled.div`
  display: none;
  margin-right: 8px;
  @media ${props => props.theme.mobileL} {
    display: block;
  }
`;

export const MobileCtaButton = styled.div`
  display: block;
`;

export const NavigationMenuLink = styled.li`
  margin: ${props => props.theme.spacing(0, 3)};
  display: flex;
  list-style: none;
  height: 44px;
  text-align: center;
  justify-content: center;
  & button {
    margin: 0;
  }
  @media ${props => props.theme.tablet} {
    ${props => props.theme.typography.body};
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const DesktopNavigationMenu = styled.menu`
  display: none;
  padding: unset;
  @media ${props => props.theme.tablet} {
    display: flex;
  }
`;

export const MobileNavigationMenu = styled.div`
  display: flex;
  @media ${props => props.theme.tablet} {
    display: none;
  }
`;

export const MobileCtaButtonContainer = styled.div`
  width: 100%;
  bottom: ${props => props.theme.spacing(10)};
  padding: 0 ${props => props.theme.spacing(3)} 0
    ${props => props.theme.spacing(3)};
`;

export const MobileCtaMemberButtonContainer = styled.div`
  width: 100%;
`;

export const MobileButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${props => props.theme.spacing(props.verticalMargin, 0)};
`;

export const BurgerMenu = styled.button`
  background: none;
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing(2)};
  border: 2px solid transparent;
  &:focus,
  &:active {
    border: 2px solid ${props => props.theme.colors.border.dark};
  }
`;

export const SlideOutMenu = styled.menu`
  color: ${props => props.theme.colors.text.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  padding: ${props => props.theme.spacing(4, 0)};
`;

export const SlideOutMenuTopSection = styled.menu`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.spacing(4, 7)};
  margin-bottom: ${props => props.theme.spacing(4)};
`;

export const SlideOutMenuCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.neutral.bone};
  border-radius: 50%;
  padding: ${props => props.theme.spacing(3)};
  margin: ${props => props.theme.spacing(0, 4)};
`;

export const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.spacing(2)};
  border: 2px solid transparent;
  &:focus,
  &:active {
    border: 2px solid ${props => props.theme.colors.border.dark};
    border-radius: 16px;
  }
`;

export const StyledStepCounter = styled.div`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.secondary};
`;
