'use client';
import { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useLocalStorage } from '../hooks';
import { OLLIE_CAMPAIGN_ID } from './constants';

// Create a context for the campaign ID
const CampaignContext = createContext(null);

// Custom hook to use the campaign context
export const useCampaign = () => useContext(CampaignContext);

// CampaignProvider component to provide the campaign ID context
export const CampaignProvider = ({ children }) => {
  const router = useRouter();
  const [campaignId, setCampaignId] = useLocalStorage(OLLIE_CAMPAIGN_ID, null);

  // Effect to set the campaign ID from the URL query parameter or local storage
  useEffect(() => {
    let campaignId;
    if (router.isReady && router.query.campaignid) {
      campaignId = router.query.campaignid;
      if (campaignId && Array.isArray(router.query.campaignid))
        campaignId = router.query.campaignid[0];
      setCampaignId(campaignId);
    } else if (router.isReady && router.query.utm_campaign) {
      //include campaign ids from iterable.
      campaignId = router.query.utm_campaign;
      if (campaignId && Array.isArray(router.query.utm_campaign))
        campaignId = router.query.utm_campaign[0];
      setCampaignId(campaignId);
    }
  }, [
    router.isReady,
    router.query.campaignid,
    router.query.utm_campaign,
    setCampaignId,
  ]);

  return (
    <CampaignContext.Provider
      value={{
        // case where the localstorage value is set to null which is stringified
        campaignId: !campaignId || campaignId === 'null' ? null : campaignId,
        setCampaignId,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

CampaignProvider.displayName = 'CampaignProvider';

CampaignProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
