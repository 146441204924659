import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { shared } from '../config';
import social_share from './social_share.jpeg';

const META_SITE_DESCRIPTION =
  'We deliver healthy, tailor-made meals for your pup, made with only the best ingredients, right to your door. Try Ollie today!';
const META_SITE_TYPE = 'website';
const META_SITE_URL = `https://${shared.DOMAIN_PUBLIC_NAME}`;
const META_SITE_TITLE = 'Ollie';
const META_IMAGE_URL = `${META_SITE_URL}/${social_share.src}`;
const CANONICAL_URL = '';

export const MetaAttributes = ({
  seoTitle = META_SITE_TITLE,
  seoDescription = META_SITE_DESCRIPTION,
  socialTitle = seoTitle,
  socialSiteName = META_SITE_TITLE,
  socialDescription = seoDescription,
  socialType = META_SITE_TYPE,
  socialUrl = META_SITE_URL,
  socialImage = META_IMAGE_URL,
  socialImageSecure = META_IMAGE_URL,
  canonicalUrl = CANONICAL_URL,
  metaTags = [],
  environment = shared.OLLIE_ENV,
}) => {
  const router = useRouter();
  const currentUrl = `${META_SITE_URL}${router.asPath}`;
  return (
    <Head>
      <title>{seoTitle}</title>
      <meta key="og:title" property="og:title" content={socialTitle} />
      <meta key="description" name="description" content={seoDescription} />
      <meta
        key="og:description"
        property="og:description"
        content={socialDescription}
      />
      <meta
        key="og:site_name"
        property="og:site_name"
        content={socialSiteName}
      />
      <meta key="og:type" property="og:type" content={socialType} />
      <meta key="og:url" property="og:url" content={socialUrl} />
      <meta key="og:image" property="og:image" content={socialImage} />
      <meta
        key="og:image:secure_url"
        property="og:image:secure_url"
        content={socialImageSecure}
      />
      <meta
        key="viewport"
        content="width=device-width"
        initial-scale="1.0"
        name="viewport"
      />
      {metaTags.map(tag => (
        <meta
          key={tag.property}
          property={tag.property}
          content={tag.content}
        />
      ))}
      {environment !== 'production' ? (
        <meta name="robots" content="noindex" />
      ) : (
        <meta name="robots" content="index" />
      )}
      <link rel="canonical" href={canonicalUrl || currentUrl} />
    </Head>
  );
};

MetaAttributes.propTypes = {
  socialTitle: PropTypes.string,
  socialSiteName: PropTypes.string,
  socialDescription: PropTypes.string,
  socialType: PropTypes.string,
  socialUrl: PropTypes.string,
  socialImage: PropTypes.string,
  socialImageSecure: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  metaTags: PropTypes.arrayOf(
    PropTypes.shape({
      property: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ),
  canonicalUrl: PropTypes.string,
  environment: PropTypes.string,
};
