'use client';
import React, { createContext, useEffect, useState } from 'react';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import { usePathname } from 'next/navigation';
import { useCart, useSessionCustomer } from '../customer';
import { ApiResult } from '../api';
import { shared } from '../config';
import { usePaymentIntent } from './usePaymentIntent';
import { STRIPE_ERRORS } from './constants';
import { usePaymentMethod } from './usePaymentMethod';
import { getPendingIntentTransactionId } from './functions';

export const createPaymentToken = async ({ stripe, elements }) =>
  ApiResult.callAsync(async () => {
    if (!stripe || !elements) {
      throw new Error('stripe or elements has not yet loaded');
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      throw error;
    }
    return token;
  });

const stripePromise = loadStripe(shared.STRIPE_PUBLISHABLE_KEY);

//* To prevent an unhandledRejection error from being passed up to Sentry, this catch was added to the original promise in the event it is rejected upon execution
stripePromise.catch(() => {});

const initialContext = {};
export const StripeContext = createContext(initialContext);

const checkoutPaths = ['/checkout'];

const editPaymentMethodPaths = [
  '/account/edit-payment',
  '/account/add-pup/payment',
  '/account/reactivate-pup/payment',
];

export const StripeElementsProvider = ({ children }) => {
  const [stripeError, setStripeError] = useState(null);
  const {
    createIntent,
    updateIntent,
    paymentIntents,
    confirmPaymentIntent,
    clearIntents,
  } = usePaymentIntent();
  const {
    initiateSetupIntent,
    confirmSetupIntent,
    setupIntent,
    removeSetupIntent,
    createPaymentMethod,
  } = usePaymentMethod();
  const { cart } = useCart();
  const { customer } = useSessionCustomer();
  const pathname = usePathname();
  const isCheckoutPath = checkoutPaths.some(path => pathname.includes(path));

  const isEditPaymentPath = editPaymentMethodPaths.some(path =>
    pathname.includes(path),
  );

  const clientSecret = getPendingIntentTransactionId(paymentIntents);
  const clientSetupSecret = setupIntent?.transaction_id;

  useEffect(() => {
    stripePromise.then(
      () => {}, // on success do nothing
      () => setStripeError({ message: STRIPE_ERRORS.stripePromiseFailed }), // on failure set the error
    );
  }, []);

  useEffect(() => {
    if (isCheckoutPath && cart?.cart_id && !clientSecret)
      (async () => {
        try {
          await createIntent(cart.cart_id);
        } catch (err) {
          setStripeError({ message: STRIPE_ERRORS.stripePromiseFailed });
        }
      })();
    if (!isCheckoutPath && clientSecret) clearIntents();
  }, [cart?.cart_id, createIntent, clearIntents, isCheckoutPath, clientSecret]);

  useEffect(() => {
    if (isEditPaymentPath && customer?.id && !clientSetupSecret) {
      (async () => {
        try {
          await initiateSetupIntent(customer.id);
        } catch (err) {
          setStripeError({ message: STRIPE_ERRORS.stripePromiseFailed });
        }
      })();
    }
    if (!isEditPaymentPath && clientSetupSecret) removeSetupIntent();
  }, [
    customer?.id,
    initiateSetupIntent,
    isEditPaymentPath,
    clientSetupSecret,
    removeSetupIntent,
  ]);

  return (
    <StripeContext.Provider
      value={{
        stripeError,
        stripePromise,
        createIntent,
        updateIntent,
        confirmPaymentIntent,
        clientSecret,
        paymentIntents,
        clientSetupSecret,
        setupIntent,
        confirmSetupIntent,
        createPaymentMethod,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

StripeElementsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
