var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f98e9561"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { Dedupe } from '@sentry/integrations';
import { shared } from './src/config';

const enabled = shared.OLLIE_ENV !== 'local';

Sentry.init({
  dsn: shared.WEB_SENTRY_DSN,
  enabled,
  environment: shared.OLLIE_ENV,
  release: shared.COMMIT_SHA,
  integrations: [new Dedupe()],
  // Filter config borrowed from here:
  // https://gist.github.com/impressiver/5092952
  // @ts-ignore browser-only config
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Firefox extensions
    /^resource:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // retargeting.js
    /static\.traversedlp\.com/i,
    // lightbox_inline.js
    /www\.lightboxcdn\.com/i,
    // privy widget
    /d3rr3d0n31t48m\.cloudfront\.net/i,
    /widget\.privy\.com/i,
    // Honey.app
    /^file:\/\//i,
  ],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Doesn't tell us anything useful
    /unspecified error/i,
    // Benign error from carousel
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    // No idea...
    "Can't find variable: SymBrowser_ModifyAnchorTagWithTarget",
    // Got this randomly once, Safari-related?
    'NS_ERROR_FILE_READ_ONLY',
    'window.getPlayer is not a function',
    "Identifier 'VIDEO_PLAYING' has already been declared",
    'notifyCurrentVideoState is not defined',
    'trackLand is not defined',
    "undefined is not an object (evaluating 'window.PopWidgetInterface.getFields')",
    "Cannot read property 'startGetFields' of null",
    'window.ztePage.move is not a function',
    "undefined is not an object (evaluating 'window.PopWidgetInterface.activeElement')",
    "undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')",
    "Can't find variable: _AutofillCallbackHandler",
    // Narvar
    /GraphQL error/,
    // Nondescript fetch error
    'TypeError: Load failed',
  ],
});
