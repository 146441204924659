'use client';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FlagshipProvider } from '@flagship.io/react-sdk';
import { useReporter } from '../reporter';
import { shared } from '../config';
import { useSession } from '../api';
import { createClientVisitor } from './createClientVisitor';
import { abCampaignEvent } from './events';
import { CAMPAIGN_TYPE } from './constants';

export const ExperimentProvider = ({
  flagshipData = { initialFlags: [], visitorId: '' },
  children,
}) => {
  const reporter = useReporter();
  const { initialFlags, visitorId } = flagshipData;
  const { data: session } = useSession();
  const visitorData = createClientVisitor(visitorId, !!session?.user?.id);
  const sendFlagshipTestsRef = useRef(true);
  const cachedInitialFlags = useRef(initialFlags);

  if (initialFlags.length) cachedInitialFlags.current = initialFlags;

  const customLog = {
    emergency(message, tag) {
      reporter.breadcrumb(`emergency - ${message} - ${tag}`);
    },

    alert(message, tag) {
      reporter.breadcrumb(`alert - ${message} - ${tag}`);
    },

    critical(message, tag) {
      reporter.breadcrumb(`critical - ${message} - ${tag}`);
    },

    error(message, tag) {
      reporter.breadcrumb(`error - ${message} - ${tag}`);
    },

    warning(message, tag) {
      reporter.breadcrumb(`warning - ${message} - ${tag}`);
    },

    notice(message, tag) {
      reporter.breadcrumb(`notice - ${message} - ${tag}`);
    },

    info(message, tag) {
      reporter.breadcrumb(`info - ${message} - ${tag}`);
    },

    debug(message, tag) {
      reporter.breadcrumb(`debug - ${message} - ${tag}`);
    },

    log(level, message, tag) {
      reporter.breadcrumb(`${level} - ${message} - ${tag}`);
    },
  };

  useEffect(() => {
    if (sendFlagshipTestsRef.current) {
      initialFlags
        ?.filter(item => item.campaignType === CAMPAIGN_TYPE)
        .map(item =>
          reporter.flag(
            abCampaignEvent({
              testName: item.key,
              variationId: item.variationId,
              variationName: item.variationName,
            }),
          ),
        );
      sendFlagshipTestsRef.current = false;
    }
  }, [reporter, initialFlags, sendFlagshipTestsRef]);
  return (
    <FlagshipProvider
      envId={shared.FLAGSHIP_ENV_ID}
      apiKey={shared.FLAGSHIP_API_KEY}
      visitorData={visitorData}
      fetchNow={true}
      initialFlagsData={cachedInitialFlags.current}
      logManager={customLog}
    >
      {children}
    </FlagshipProvider>
  );
};

ExperimentProvider.displayName = 'ExperimentProvider';

ExperimentProvider.propTypes = {
  flagshipData: PropTypes.shape({
    initialFlags: PropTypes.array,
  }),
  children: PropTypes.node.isRequired,
};
