import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HeaderButton } from '../Header/HeaderButton';
import { SlideDownMenuSlider } from './SlideDownMenuSlider';

export const SlideDownMenuLink = ({ header, categories, onCloseMenu }) => {
  const [active, setActive] = useState(false);
  const handleCloseMenu = () => {
    onCloseMenu?.();
    setActive(false);
  };
  const handleGoBack = () => setActive(false);

  const handleOpenAndClose = () => {
    if (active === false) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  return (
    <>
      <HeaderButton
        text={header}
        onClick={() => {
          handleOpenAndClose();
        }}
      />
      <SlideDownMenuSlider
        header={header}
        categories={categories}
        onCloseMenu={handleCloseMenu}
        active={active}
        onGoBack={handleGoBack}
      />
    </>
  );
};

SlideDownMenuLink.propTypes = {
  categories: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  onCloseMenu: PropTypes.func,
};
