'use client';
import PropTypes from 'prop-types';
import axios from 'axios';
import { SWRConfig, unstable_serialize } from 'swr';
import React, { createContext, useContext } from 'react';
import { shared } from '../../config';
import { BASE, SESSION_KEY } from './constants';
import { useSingleton } from './container';

class Cache extends Map {
  constructor(initialSession) {
    /** @type {[string, import('swr').State][]} */
    const data = [];
    if (initialSession) {
      data.push([
        unstable_serialize(SESSION_KEY),
        {
          error: undefined,
          isValidating: false,
          isLoading: false,
          data: initialSession,
        },
      ]);
    }

    super(data);
  }
}

const ClientContext = createContext(null);

export const useClient = () => useContext(ClientContext);

export const ClientProvider = ({
  initialSession,
  swrConfig = {},
  children,
}) => {
  const cache = useSingleton(Cache, () => new Cache(initialSession));
  const client = useSingleton('client', () => {
    const client = axios.create({
      baseURL: shared.OLLIE_ENV === 'test' ? '' : BASE(),
    });
    client.interceptors.request.use(request => {
      const cachedData = cache.get(unstable_serialize(SESSION_KEY));
      const token = cachedData?.data?.user?.access_token;
      if (token) {
        request.headers['Authorization'] = `Bearer ${token}`;
      }
      request.headers['X-Referer'] =
        typeof window !== 'undefined' ? window.location.href : 'https://node';
      return request;
    });
    return client;
  });
  const fetcher = useSingleton(
    'fetcher',
    () => url => client.get(url).then(res => res.data),
  );
  return (
    <SWRConfig
      value={{
        fetcher,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        provider: () => cache,
        ...swrConfig,
      }}
    >
      <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
    </SWRConfig>
  );
};

ClientProvider.propTypes = {
  initialSession: PropTypes.object,
  swrConfig: PropTypes.object,
  children: PropTypes.node,
};
