'use client';
import { useContext } from 'react';
import { useStripe as stripe_useStripe } from '@stripe/react-stripe-js';
import { StripeContext } from './stripe';

export const useStripe = () => {
  const stripe = stripe_useStripe();
  const {
    stripeError,
    createIntent,
    updateIntent,
    confirmPaymentIntent,
    clientSecret,
    paymentIntents,
    clientSetupSecret,
    setupIntent,
    confirmSetupIntent,
    createPaymentMethod,
    ...rest
  } = useContext(StripeContext);

  // Explicitly returning these vs spreading so that TS check doesn't complain
  return {
    stripe,
    stripeError,
    createIntent,
    updateIntent,
    confirmPaymentIntent,
    clientSecret,
    paymentIntents,
    clientSetupSecret,
    setupIntent,
    confirmSetupIntent,
    createPaymentMethod,
    ...rest,
  };
};
