import React from 'react';
import PropTypes from 'prop-types';
import { ClientProvider } from './client';
import { SessionProvider } from './session';
import { ContainerProvider } from './container';

export { useClient } from './client';
export { useSession } from './session';
export { useService, BaseService } from './service';

export const ApiProvider = ({ initialSession, swrConfig, children }) => {
  return (
    <ContainerProvider>
      <ClientProvider initialSession={initialSession} swrConfig={swrConfig}>
        <SessionProvider>{children}</SessionProvider>
      </ClientProvider>
    </ContainerProvider>
  );
};

ApiProvider.propTypes = {
  initialSession: PropTypes.object,
  swrConfig: PropTypes.object,
  children: PropTypes.node,
};
