import twitterIcon from '../../images/Footer/twitter.svg';
import pinterestIcon from '../../images/Footer/pinterest.svg';
import instagramIcon from '../../images/Footer/instagram.svg';
import facebookIcon from '../../images/Footer/facebook.svg';
import spotifyIcon from '../../images/Footer/spotify.svg';
import tiktokIcon from '../../images/Footer/tiktok.svg';
import appleAppStoreButtonImage from '../../images/Footer/footer-app-store-button.svg';
import googlePlayButtonImage from '../../images/Footer/footer-google-play-button.svg';
import {
  userClicksOnAppleDownloadButton,
  userClicksOnGoogleDownloadButton,
} from './events';

export const socialMediaActionText = 'Opens in new tab';

export const socialMediaIcons = [
  {
    src: instagramIcon,
    alt: 'Instagram icon',
    href: 'https://www.instagram.com/ollie/',
  },
  {
    src: facebookIcon,
    alt: 'Facebook icon',
    href: 'https://www.facebook.com/MyOllie',
  },
  {
    src: twitterIcon,
    alt: 'Twitter icon',
    href: 'https://twitter.com/olliepets',
  },
  {
    src: tiktokIcon,
    alt: 'Tiktok icon',
    href: 'https://www.tiktok.com/@ollie',
  },
  {
    src: pinterestIcon,
    alt: 'Pinterest icon',
    href: 'https://www.pinterest.com/myolliedotcom/',
  },
  {
    src: spotifyIcon,
    alt: 'Spotify icon',
    href: 'https://open.spotify.com/user/ski5m70d8oulhj06d5ss6w1xm',
  },
];

export const OLLIE_APP_APP_STORE_LINKS = [
  {
    src: appleAppStoreButtonImage,
    alt: 'Download on the Apple App Store',
    href: 'https://apps.apple.com/us/app/ollie-human-grade-dog-food/id1614301164 ',
    clickEvent: () => userClicksOnAppleDownloadButton(),
  },
  {
    src: googlePlayButtonImage,
    alt: 'GET IT ON Google Play',
    href: 'https://play.google.com/store/apps/details?id=com.ollie.mobile_app ',
    clickEvent: () => userClicksOnGoogleDownloadButton(),
  },
];
