'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { VisuallyHidden } from '@web/atoms';
import { useReporter } from '../../reporter';
import {
  useExperiment,
  OLLIE_APP_FOOTER_DOWNLOAD_BUTTONS_KEY,
} from '../../experiments';
import {
  socialMediaIcons,
  OLLIE_APP_APP_STORE_LINKS,
  socialMediaActionText,
} from './constants';
import {
  StyledFooterColumn,
  BusinessHours,
  Content,
  ColumnTitle,
  SocialIcon,
  OllieAppButtonsContainer,
} from './styles';
import { FooterLink } from './FooterLink';

export const FooterColumn = ({ links, businessHours, content, title }) => (
  <StyledFooterColumn>
    <ColumnTitle>{title}</ColumnTitle>
    {businessHours && <BusinessHours>{businessHours}</BusinessHours>}
    {content && <Content>{content}</Content>}
    {links.map(({ path, ...linkProps }, idx) => (
      <FooterLink
        {...linkProps}
        href={path}
        key={path}
        columnTitle={title}
        index={idx}
      />
    ))}
  </StyledFooterColumn>
);

export const SocialColumn = () => {
  const reporter = useReporter();
  const { value: showOllieAppDownloadButtons } = useExperiment(
    OLLIE_APP_FOOTER_DOWNLOAD_BUTTONS_KEY,
    false,
  );
  return (
    <StyledFooterColumn>
      <ColumnTitle>Social</ColumnTitle>
      {socialMediaIcons.map(({ src, alt, href }, i) => (
        <SocialIcon key={i} href={href} target="_blank">
          <ResponsiveImage
            src={src}
            alt={alt}
            height={20}
            width={20}
            style={{ height: 'revert-layer' }}
          />
          <VisuallyHidden>{socialMediaActionText}</VisuallyHidden>
        </SocialIcon>
      ))}
      {showOllieAppDownloadButtons && (
        <>
          <ColumnTitle>GET THE OLLIE APP</ColumnTitle>
          <OllieAppButtonsContainer>
            {OLLIE_APP_APP_STORE_LINKS.map(
              ({ src, alt, href, clickEvent }, i) => (
                <a
                  key={i}
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => reporter.tag(clickEvent())}
                >
                  <ResponsiveImage
                    src={src}
                    alt={alt}
                    height={56}
                    width={189}
                  />
                </a>
              ),
            )}
          </OllieAppButtonsContainer>
        </>
      )}
    </StyledFooterColumn>
  );
};

SocialColumn.displayName = 'SocialColumn';

FooterColumn.displayName = 'FooterColumn';

FooterColumn.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
  businessHours: PropTypes.node,
  content: PropTypes.node,
  title: PropTypes.string.isRequired,
};
